<template>
  <div>
    <v-form class="ml-2" ref="form">
      <div>
        <div class="c-one-title mt-2">数据同步</div>
        <div class="mt-4 d-flex flex-wrap">
          <v-col cols="3">
            <w-autocomplete @change="change" :items="opsItems" label="同步中台设置数据"/>
          </v-col>
          <v-col cols="3" v-if="form.ops">
            <w-autocomplete :label="label" :items="syncgroupItems" @change="form.syncgroup = $event"/>
          </v-col>
        </div>
        <div>
          <v-btn depressed color="primary" @click="sync" :loading="loading">
            <v-icon class="iconfont" size="16">iconfont icon-tongbu</v-icon>
            同步基础数据
          </v-btn>
          <v-btn depressed color="primary" class="ml-4" @click="syncPriceStore">
            <v-icon class="iconfont" size="16">iconfont icon-tongbu</v-icon>
            同步库存&价格
          </v-btn>
        </div>
      </div>
    </v-form>
    <form-menu
        ref="formRef"
        :listDeploy="listDeploy"
        :dialog.sync="dialog"
        title="同步库存&价格"
        rightbtn_text="同步数据"
        @validateSave="validateSave"/>

    <v-footer
        style="background: #ffffff; padding: 20px 24px"
        padless
        absolute
    >
      <v-btn @click="refreshCache" depressed outlined color="primary">刷新缓存</v-btn>
    </v-footer>
  </div>
</template>

<script>

import api from '@/api/systemManage/sys-utils.js'
import wAutocomplete from '@/components/w-autocomplete/w-autocomplete.vue';
import formMenu from "@/components/form-menu/form-menu.vue";
import rangeTime from '@/components/dateTime/rangeTime.vue';

export default {
  data() {
    return {
      api,
      form: {
        ops: '',
        syncgroup: ''
      },
      opsItems: [],
      syncgroupItems: [],
      label: '',
      loading: false,
      dialog: false,
      listDeploy: {
        row: [
          {
            cols: 6,
            subassembly: "WAutocomplete",
            property: 'ops',
            connector: {
              on: {
                especially: (k) => {
                  this.$set(this.listDeploy.row[2].connector.props, 'value', [])
                  if (k) {
                    const p = k.replace('detail', '')
                    this.axios.post(this.select_data, {keyname: p, params: [{key: p, value: 'all'}]}).then((res) => {
                      if (res.data[0].values) {
                        this.listDeploy.row[2].connector.props.items = res.data[0].values
                      }
                    })
                  }
                }
              },
              props: {
                required: true,
                label: "业务类型",
                items: []
              }
            }
          },
          {
            cols: 6,
            subassembly: rangeTime,
            property: ["startDate", "endDate"],
            deconstruction: function (args) {
              return args.split(" ~ ")
            },
            connector: {
              props: {
                required: true,
                label: "开始日期 - 结束日期"
              }
            }
          },
          {
            cols: 6,
            subassembly: "WAutocomplete",
            property: 'product',
            connector: {
              props: {
                required: true,
                label: "产品小类",
                multiple: true,
                chips: true,
                items: [],
                arrayType: false
              }
            }
          }
        ]
      }
    }
  },
  components: {wAutocomplete, formMenu},
  methods: {
    sync() {
      if (!this.form.syncgroup) {
        this.form.syncgroup = 'all'
      }
      if (this.form.ops) {
        this.loading = true
        this.axios.post(api.sync, this.form).then((res) => {
          this.loading = false
          if (res.code === this.staticVal.Code.Success) {
            this.snackbar.success('基础数据同步成功')
          }
        }).catch(() => {
          this.loading = false
        })
      } else {
        this.snackbar.info('请选择要同步的PMS设置数据')
      }
    },
    refreshCache() {
      this.axios.post(api.refresh_cache).then((res) => {
        if (res.code === this.staticVal.Code.Success) {
          this.snackbar.success('缓存刷新成功')
        }
      })
    },
    change(k) {
      this.form.ops = k
      this.form.syncgroup = ''
      this.label = ''
      if (k) {
        const p = k === 'roomtype' ? 'hotel' : k
        this.axios.post(this.select_data, {keyname: p, params: [{key: p, value: 'all'}]}).then((res) => {
          if (res.data[0].values) {
            this.syncgroupItems = res.data[0].values
          }
        })
        if (k === 'roomtype') {
          this.label = '输入酒店代码'
        } else {
          this.opsItems.forEach((item) => {
            if (item.code === k) {
              this.label = '输入' + item.desc + '代码'
              return false
            }
          })
        }
      }
    },
    syncPriceStore() {
      this.axios.post(this.select_data, {keyname: 'syncresource'}).then((res) => {
        if (res.data[0].values) {
          this.listDeploy.row[0].connector.props.items = res.data[0].values
        }
      })
      this.dialog = true
    },
    validateSave(item, callback) {
      if (item.done) {
        this.axios.post(api.sync_product_resource, item.form).then((res) => {
          callback();
          this.snackbar.success(res.code === this.staticVal.Code.Success ? '同步成功' : '同步失败')
        })
      }
    }
  },
  mounted() {
    this.axios.post(this.select_data, {keyname: 'syncdata'}).then((res) => {
      if (res.data[0]) {
        this.opsItems = res.data[0].values
      }
    })
  }
}
</script>

<style scoped lang="scss">
.c-one-title {
  font-size: 18px;
  font-weight: bold;
  border-left: 2px solid var(--v-primary-base);
  padding-left: 8px;
  color: #333333;
}

::v-deep {
  .v-label {
    font-size: 14px;
  }

  .col-3 {
    padding-left: 0;
  }
}
</style>
